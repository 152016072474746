<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@utils/utils'

const props = defineProps({
  class: null
})
</script>

<template>
  <div
    :class="
      cn(
        'rounded-lg border bg-card text-card-foreground shadow-lg',
        props.class,
      )
    "
  >
    <slot />
  </div>
</template>
