
            import { createApp } from "vue";
            import App from "./App.vue";

            import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
            import { library } from "@fortawesome/fontawesome-svg-core";

            import {
                faRotateRight,
                faClipboard,
                faPause,
                faPlay,
                faPaintRoller,
            } from "@fortawesome/free-solid-svg-icons";

            library.add(faRotateRight, faClipboard, faPause, faPlay, faPaintRoller);
            const app = createApp(App);
            app.component("font-awesome-icon", FontAwesomeIcon).mount("#app");
        