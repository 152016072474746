<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { cn } from "@utils/utils";

const props = defineProps({
  class: null
});
</script>

<template>
    <h3
        :class="
            cn(
                'text-5xl font-semibold leading-none tracking-tight fraunces',
                props.class,
            )
        "
    >
        <slot />
    </h3>
</template>
